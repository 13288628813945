import { observable } from "mobx";
import ManufactureItem from "../../../model/manufacture/ManufactureItem";
import ManufactureWork from "../../../model/manufacture/ManufactureWork";
import StoreBase from "../../StoreBase";
import { ItemPresetKeyType, ItemPresetList, ItemVersion, PresetItemPackage } from "@celemicsbisw/manufacture-item-structure";
import { ManufactureItemOption } from "../../../model/manufacture/ManufactureItemOption";

export default class ManufactureItemFormStore extends StoreBase {
    @observable data: ManufactureWork;
    @observable itemList: ManufactureItem[];
    @observable indexList: string[];
    @observable inputHeaders: (keyof ManufactureItem)[];
    @observable readonlyHeaders: (keyof ManufactureItem)[];
    @observable selectedProductType: 'illumina' | 'ionTorrent' | 'etc' | '';

    @observable selectedItemPreset: ItemPresetKeyType;
    @observable itemPresetList: ItemPresetKeyType[];
    @observable presetItemOptions: ManufactureItemOption;
    @observable presetLoaded: boolean;
    @observable itemVersion?: ItemVersion;
    packageOptions: PresetItemPackage;

    constructor() {
        super();
        this.indexList = [];
        this.inputHeaders = [];
        this.readonlyHeaders = [];
        this.itemList = [];
        this.selectedProductType = '';
        this.selectedItemPreset = 'custom';
        this.presetItemOptions = new ManufactureItemOption(false, 'preset');
        this.packageOptions = null;
        this.itemPresetList = Object.keys(ItemPresetList) as ItemPresetKeyType[];
        this.presetLoaded = false;
        this.itemVersion = null;
    }

    addNewItem = (item: ManufactureItem) => {
        this.itemList.push(item);
    }

    addNewEmptyItem = () => {
        this.itemList.push(new ManufactureItem({
            optionReadonly: this.readonlyHeaders.length > 0
        }));
    }

    clearItems = () => {
        while (this.itemList.length > 0) {
            this.itemList.pop();
        }
    }

    resetPreset = () => {
        this.presetItemOptions = new ManufactureItemOption(false, 'preset');
        this.selectedItemPreset = 'custom';
    }

    clearIndexList = () => {
        while (this.indexList.length > 0) {
            this.indexList.pop();
        }
    }

    setItemList = (items: ManufactureItem[]) => {
        items.map((v) => {
            this.itemList.push(v);
            return null;
        })
    }

    setIndexList = (list: string[]) => {
        list.map((v) => {
            this.indexList.push(v);
            return null;
        })
    }

    setItemListByProductType = (type: string) => {
        const list = this.getItemsBySelectedType(type);
        this.setItemList(list);
    }

    setIndexListByProductType = (type: string) => {
        const list = this.getIndexListByProductType(type);
        this.setIndexList(list);
    }

    deleteItem = (index: number) => {
        try {
            this.itemList.splice(index, 1);
        } catch (err) {
            throw err;
        }
    }

    getItemsBySelectedType = (type: string): ManufactureItem[] => {
        switch (type) {
            case 'illumina':
                return this.getIlluminaItems();
            case 'ionTorrent':
                return this.getIonTorrentItems();
            case 'etc':
                return this.getEtcItems();
            default:
                return [];
        }
    }

    getIlluminaItems = (): ManufactureItem[] => {
        const list: ManufactureItem[] = [];
        list.push(new ManufactureItem({
            itemName: `${ManufactureItem.ItemNameList.lp} #1`,
            no: 1,
            unit: 'rxn',
            numberPerQty: 16,
            validateList: this.inputHeaders,
        }));
        list.push(new ManufactureItem({
            itemName: `${ManufactureItem.ItemNameList.lp} #2`,
            no: 2,
            unit: 'rxn',
            numberPerQty: 16,
            validateList: this.inputHeaders,
        }));
        list.push(new ManufactureItem({
            itemName: `${ManufactureItem.ItemNameList.tc} #1`,
            no: 1,
            unit: 'rxn',
            numberPerQty: 16,
            validateList: this.inputHeaders,
        }));
        list.push(new ManufactureItem({
            itemName: `${ManufactureItem.ItemNameList.tc} #2`,
            no: 2,
            unit: 'rxn',
            numberPerQty: 16,
            validateList: this.inputHeaders,
        }));
        list.push(new ManufactureItem({
            itemName: `${ManufactureItem.ItemNameList.tc} #3`,
            no: 3,
            unit: 'rxn',
            numberPerQty: 16,
            validateList: this.inputHeaders,
        }));
        list.push(new ManufactureItem({
            itemName: ManufactureItem.ItemNameList.cleanUp,
            unit: 'ml',
            numberPerQty: 16,
            validateList: this.inputHeaders,
        }));
        list.push(new ManufactureItem({
            itemName: ManufactureItem.ItemNameList.st,
            unit: 'ml',
            numberPerQty: 16,
            validateList: this.inputHeaders,
        }));
        list.push(new ManufactureItem({
            itemName: ManufactureItem.ItemNameList.polymerase,
            unit: 'ml',
            numberPerQty: 16,
            validateList: this.inputHeaders,
        }));
        return list;
    }

    getIonTorrentItems = (): ManufactureItem[] => {
        const list: ManufactureItem[] = [];
        list.push(new ManufactureItem({
            itemName: `${ManufactureItem.ItemNameList.lp} #1`,
            no: 1,
            unit: 'rxn',
            numberPerQty: 16,
            validateList: this.inputHeaders,
        }));
        list.push(new ManufactureItem({
            itemName: `${ManufactureItem.ItemNameList.lp} #2`,
            no: 2,
            unit: 'rxn',
            numberPerQty: 16,
            validateList: this.inputHeaders,
        }));
        list.push(new ManufactureItem({
            itemName: `${ManufactureItem.ItemNameList.tc} #1`,
            no: 1,
            unit: 'rxn',
            numberPerQty: 16,
            validateList: this.inputHeaders,
        }));
        list.push(new ManufactureItem({
            itemName: `${ManufactureItem.ItemNameList.tc} #2`,
            no: 2,
            unit: 'rxn',
            numberPerQty: 16,
            validateList: this.inputHeaders,
        }));
        list.push(new ManufactureItem({
            itemName: `${ManufactureItem.ItemNameList.tc} #3`,
            no: 3,
            unit: 'rxn',
            numberPerQty: 16,
            validateList: this.inputHeaders,
        }));
        list.push(new ManufactureItem({
            itemName: ManufactureItem.ItemNameList.cleanUp,
            unit: 'ml',
            numberPerQty: 16,
            validateList: this.inputHeaders,
        }));
        list.push(new ManufactureItem({
            itemName: ManufactureItem.ItemNameList.st,
            unit: 'ml',
            numberPerQty: 16,
            validateList: this.inputHeaders,
        }));
        list.push(new ManufactureItem({
            itemName: ManufactureItem.ItemNameList.polymerase,
            unit: 'ml',
            numberPerQty: 16,
            validateList: this.inputHeaders,
        }));
        return list;
    }

    getEtcItems = (): ManufactureItem[] => {
        const list: ManufactureItem[] = [];
        return list;
    }

    getIndexListByProductType = (type: string): string[] => {
        const list: string[] = [];
        switch (type) {
            case 'illumina':
                list.push('none');
                list.push('single');
                list.push('dual');
                break;
            case 'illuminaEP':
                list.push('none');
                list.push('single');
                list.push('dual');
                break;
            case 'illuminaCS':
                list.push('none');
                list.push('single');
                list.push('dual');
                break;
            case 'ionTorrent':
                list.push('none');
                list.push('ionXpress');
                break;
            case 'etc':
                list.push('none');
                list.push('single');
                list.push('dual');
                list.push('barcode');
                list.push('ionXpress');
                break;
            case 'none':
                list.push('none');
                break;
            default:
                break;
        }
        return list;
    }

    itemOptionsToManufactureItem(options: ManufactureItemOption) {
        const item = new ManufactureItem();
        switch (options.itemType) {
            case 'lp1':
                item.itemName = 'LP#1';
                item.itemOptions.lpep = options.lpep;
                break;
            case 'lp2':
                item.itemName = 'LP#2';
                item.itemOptions.indexType = options.indexType;
                item.itemOptions.adapterType = options.adapterType;
                break;
            case 'tc1':
                item.itemName = 'TC#1';
                item.itemOptions.tc1Enhancer = options.tc1Enhancer;
                item.itemOptions.indexType = options.indexType;
                break;
            case 'tc2':
                item.itemName = 'TC#2';
                item.itemOptions.tc2Enhancer = options.tc2Enhancer;
                break;
            case 'tc3':
                item.itemName = 'TC#3';
                item.itemOptions.probeState = options.probeState;
                if (options.version === 'v3') {
                    item.itemOptions.probeConc = options.probeConc;
                }
                break;
            case 'cDNA':
                item.itemName = 'cDNA Synthesis Kit';
                item.itemOptions.probeState = options.probeState;
                break;
            case 'accClmBead':
                item.itemName = 'NM bead';
                item.itemOptions.probeState = options.probeState;
                break;
            case 'accClmCelanUpBead':
                item.itemName = 'Clean-up bead';
                item.itemOptions.probeState = options.probeState;
                break;
            case 'accClmStreptavidinBead':
                item.itemName = 'Streptavidin bead';
                item.itemOptions.probeState = options.probeState;
                break;
            case 'accClmPolymerase':
                item.itemName = 'Polymerase';
                item.itemOptions.probeState = options.probeState;
                break;
            case 'accClmBeadBindingBuffer':
                item.itemName = 'NM bead binding buffer';
                item.itemOptions.probeState = options.probeState;
                break;
            default:
                break;
        }
        item.itemOptions.platform = options.platform;
        item.itemOptions.readonly = true;
        item.version = options.version;
        item.itemOptions.version = options.version;
        return item;
    }

    getPresetItemList = () => {
        const pack = new PresetItemPackage({
            platform: this.presetItemOptions.platform,
            adapter: this.presetItemOptions.adapterType,
            lpep: this.presetItemOptions.lpep,
            indexType: this.presetItemOptions.indexType,
            tc1Enhancer: this.presetItemOptions.tc1Enhancer,
            tc2Enhancer: this.presetItemOptions.tc2Enhancer,
            probeState: this.presetItemOptions.probeState,
            selectedItemPreset: this.selectedItemPreset,
            probeConc: this.presetItemOptions.probeConc,
            itemVersion: this.itemVersion,
        });
        pack.setItemListBySelection();
        pack.syncAllToItems();
        this.packageOptions = pack;
        const list = pack.itemList.map(v => {
            const options = new ManufactureItemOption(false, 'options');
            switch (v.type) {
                case 'lp1':
                    options.itemType = 'lp1';
                    options.platform = pack.platform;
                    options.lpep = pack.lpep;
                    break;
                case 'lp2':
                    options.itemType = 'lp2';
                    options.platform = pack.platform;
                    options.indexType = pack.indexType;
                    options.adapterType = pack.adapter;
                    break;
                case 'tc1':
                    options.itemType = 'tc1';
                    options.platform = pack.platform;
                    options.tc1Enhancer = pack.tc1Enhancer;
                    options.indexType = pack.indexType;
                    break;
                case 'tc2':
                    options.itemType = 'tc2';
                    options.platform = pack.platform;
                    options.tc2Enhancer = pack.tc2Enhancer;
                    break;
                case 'tc3':
                    options.itemType = 'tc3';
                    options.platform = pack.platform;
                    options.probeState = pack.probeState;
                    options.probeConc = pack.probeConc;
                    if (pack.itemVersion === 'v3') {
                        options.probeConc = pack.probeConc;
                    }
                    break;
                case 'cDNA':
                    options.itemType = 'cDNA';
                    options.platform = pack.platform;
                    break;
                case 'accClmBead':
                    options.itemType = 'accClmBead';
                    options.platform = pack.platform;
                    break;
                case 'accClmCelanUpBead':
                    options.itemType = 'accClmCelanUpBead';
                    options.platform = pack.platform;
                    break;
                case 'accClmStreptavidinBead':
                    options.itemType = 'accClmStreptavidinBead';
                    options.platform = pack.platform;
                    break;
                case 'accClmPolymerase':
                    options.itemType = 'accClmPolymerase';
                    options.platform = pack.platform;
                    break;
                case 'accClmBeadBindingBuffer':
                    options.itemType = 'accClmBeadBindingBuffer';
                    options.platform = pack.platform;
                    break;
                default:
                    break;
            }
            options.version = this.itemVersion;
            return this.itemOptionsToManufactureItem(options);
        });
        return list;
    }

    onPresetItemGenerate = (mode: 'add' | 'gen') => {
        this.presetItemOptions.resetErrorsRecursive();
        this.presetItemOptions.validateSelf();
        if (this.presetItemOptions.hasError()) {
            return;
        }
        else {
            if (mode === 'gen') {
                this.clearItems();
            }
            const list = this.getPresetItemList();
            list.forEach(v => this.itemList.push(v));
        }
    }
}
