import { action, observable } from "mobx";
import Validatable from "../common/Validatable";
import { Helper as MSHelper, Adapter, AdapterType, ComposedSampleType, IndexType, ItemBoxBuilder, ItemBoxController, ItemType, LP1Box, LP2Box, LPEPOption, PlateType, Platform, Probe, TC1Box, TC2Box, TC3Box, UnitType, storageTempKeyType, ProbeConc, ItemVersion } from "@celemicsbisw/manufacture-item-structure";

export type TC1Version = 'v1' | 'v2';

export interface IManufactureItemOption {
    itemType?: ItemType;
    platform?: Platform;
    lpep?: LPEPOption;
    indexType?: IndexType;
    adapter?: Adapter;
    plate?: PlateType;
    tc1Enhancer?: boolean;
    tc2Enhancer?: boolean;
    version?: ItemVersion;
    mode?: Mode;
}

export type ManufactureOptionSelectionType = {
    label: string,
    value: any,
}

export type ProbeState = 'solution' | 'powder';
type Mode = 'preset' | 'options' | null;

export class ManufactureItemOption extends Validatable {

    itemType?: ItemType;
    rxn: number | null;
    probeID: string | null;
    selectedUnit: UnitType;
    storageTemp?: storageTempKeyType;
    @observable platform?: Platform;
    @observable lpep?: LPEPOption;
    @observable indexType?: IndexType;
    @observable adapterType?: AdapterType;
    @observable plateType?: PlateType;
    @observable tc1Enhancer?: boolean;
    @observable tc2Enhancer?: boolean;
    @observable composedSampleType: ComposedSampleType;
    @observable probeState: ProbeState;
    @observable version?: ItemVersion;
    @observable probeConc?: ProbeConc;

    @observable readonly: boolean;
    mode: Mode;

    constructor(readonly: boolean = false, mode: Mode = 'options') {
        super();
        this.itemType = null;
        this.platform = null;
        this.lpep = null;
        this.indexType = null;
        this.adapterType = null;
        this.plateType = "*";
        this.probeState = null;
        this.rxn = null;
        this.selectedUnit = 'rxn';
        this.tc1Enhancer = false;
        this.tc2Enhancer = false;
        this.composedSampleType = 'dna_lp';
        this.version = null;
        this.readonly = readonly;
        this.mode = mode;
        if (!readonly) {
            this.initErrorsRecursive();
        }
    }

    getItemBoxLabelName() {
        switch (this.itemType) {
            case 'lp1':
                return '1. Library preparation Box#1';
            case 'lp2':
                return '2. Library preparation Box#2';
            case 'tc1':
                return '3. Target capture solution Box#1';
            case 'tc2':
                return '4. Target capture solution Box#2';
            case 'tc3':
                return '5. Target capture solution Box#3';
            case 'cDNA':
                return 'cDNA Synthesis Kit';
            default: return null;
        }
    }

    /**
     * @TODO 아이템 클래스별로 validate 인터페이스 구현하게 해야함
     * @returns
     */
    validateSelf(): void {
        if (this.readonly) {
            return;
        }
        if (this.mode === 'options') {
            if (this.itemType === 'lp1') {
                if (!this.platform) {
                    this.setErrorTextByName('platform', Validatable.errorTexts.needValue);
                }
                if (!this.lpep) {
                    this.setErrorTextByName('lpep', Validatable.errorTexts.needValue);
                }
                if (this.version !== 'v3') {
                    if (this.platform === 'ionTorrent' && this.lpep === 'ep') {
                        this.setErrorTextByName('lpep', 'Ion torrent EP 는 Version 3 에서만 가능합니다.');
                    }
                }
                // if (!this.version) {
                //     this.setErrorTextByName('version', Validatable.errorTexts.needValue);
                // }
            }
            if (this.itemType === 'lp2') {
                if (!this.platform) {
                    this.setErrorTextByName('platform', Validatable.errorTexts.needValue);
                }
                if (!this.adapterType) {
                    this.setErrorTextByName('adapterType', Validatable.errorTexts.needValue);
                }
                if (!this.indexType) {
                    this.setErrorTextByName('indexType', Validatable.errorTexts.needValue);
                }
                // if (!this.version) {
                //     this.setErrorTextByName('version', Validatable.errorTexts.needValue);
                // }
            }
            if (this.itemType === 'tc1') {
                if (!this.platform) {
                    this.setErrorTextByName('platform', Validatable.errorTexts.needValue);
                }
                if (!this.indexType) {
                    this.setErrorTextByName('indexType', Validatable.errorTexts.needValue);
                }
                // if (!this.version) {
                //     this.setErrorTextByName('version', Validatable.errorTexts.needValue);
                // }
            }
            if (this.itemType === 'tc2') {
                if (!this.platform) {
                    this.setErrorTextByName('platform', Validatable.errorTexts.needValue);
                }
                // if (!this.version) {
                //     this.setErrorTextByName('version', Validatable.errorTexts.needValue);
                // }
            }
            if (this.itemType === 'tc3') {
                if (!this.platform) {
                    this.setErrorTextByName('platform', Validatable.errorTexts.needValue);
                }
                if (!this.probeState) {
                    this.setErrorTextByName('probeState', Validatable.errorTexts.needValue);
                }
                if (this.version === 'v3') {
                    if (!this.probeConc) {
                        this.setErrorTextByName('probeConc', Validatable.errorTexts.needValue);
                    }
                }
            }
            // if (this.itemType === 'cDNA') {
            //     if (!this.version) {
            //         this.setErrorTextByName('version', Validatable.errorTexts.needValue);
            //     }
            // }
        }
        else if (this.mode === 'preset') {
            if (!this.platform) {
                this.setErrorTextByName('platform', Validatable.errorTexts.needValue);
            }
            if (!this.lpep) {
                this.setErrorTextByName('lpep', Validatable.errorTexts.needValue);
            }
            if (this.version !== 'v3') {
                if (this.platform === 'ionTorrent' && this.lpep === 'ep') {
                    this.setErrorTextByName('lpep', 'Ion torrent EP 는 Version 3 에서만 가능합니다.');
                }
            }
            if (!this.adapterType) {
                this.setErrorTextByName('adapterType', Validatable.errorTexts.needValue);
            }
            if (!this.indexType) {
                this.setErrorTextByName('indexType', Validatable.errorTexts.needValue);
            }
            if (!this.probeState) {
                this.setErrorTextByName('probeState', Validatable.errorTexts.needValue);
            }
            if (this.version === 'v3') {
                if (!this.probeConc) {
                    this.setErrorTextByName('probeConc', Validatable.errorTexts.needValue);
                }
            }
        }
    }

    getAdapterList(): ManufactureOptionSelectionType[] {
        return MSHelper.extractElseSelectionInfosByKey(this.platform, this.composedSampleType, 'adapter');
    }

    getIndexTypeList(): ManufactureOptionSelectionType[] {
        const result = MSHelper.extractElseSelectionInfosByKey(this.platform, this.composedSampleType, 'indexType');
        return result;
    }

    @action.bound
    onChangeOptionValue = (key: keyof ManufactureItemOption, value: any) => {
        (this as any)[key] = value;
    }

    @action.bound
    reset = () => {
        this.itemType = null;
        this.platform = null;
        this.lpep = null;
        this.indexType = null;
        this.adapterType = null;
        this.plateType = "*";
        this.probeState = null;
        this.tc1Enhancer = false;
        this.tc2Enhancer = false;
        this.initErrorsRecursive();
    }

    private serializeThis() {
        return {
            // type: this.type,
            rxn: this.rxn,
            platform: this.platform,
            lpep: this.lpep,
            indexType: this.indexType,
            plateType: this.plateType,
            tc1Enhancer: this.tc1Enhancer,
            tc2Enhancer: this.tc2Enhancer,
            composedSampleType: this.composedSampleType,
            probeState: this.probeState,
            selectedUnit: this.selectedUnit,
            storageTemp: this.storageTemp,
            version: this.version,
        }
    }

    getItemBox() {
        const item = ItemBoxBuilder.build(this.itemType, { ...this.serializeThis() });
        if (item instanceof LP1Box) {
            item.lpep = this.lpep;
        }
        if (item instanceof LP2Box) {
            item.indexType = this.indexType;
            item.plateType = this.plateType;
            ItemBoxController.setAdpaterByJSON(item, this.adapterType);
        }
        if (item instanceof TC1Box) {
            item.enhancer = this.tc1Enhancer;
            item.indexType = this.indexType;
        }
        if (item instanceof TC2Box) {
            item.enhancer = this.tc2Enhancer;
        }
        if (item instanceof TC3Box) {
            item.probe = new Probe({
                probeId: this.probeID,
                name: this.probeID,
                state: this.probeState,
            });
            if (this.version === 'v3') {
                item.probeConc = this.probeConc;
            }
        }

        ItemBoxController.setReagentsByJSON(item);
        item.subReagentCode = item.getCalculatedSubReagentCode();
        return item;
    }

    translateProbeConc() {
        switch (this.probeConc) {
            case 'low':
                return 'Low';
            case 'high':
                return 'High';
            case 'methyl':
                return 'Methyl';
            case 'etc':
                return 'etc.';
            default:
                return '';
        }
    }

    getVersionString() {
        switch (this.version) {
            case 'v1':
                return 'V1';
            case 'v2':
                return 'V2';
            case 'v3':
                return 'V3';
            default:
                return '';
        }
    }

    getOptionsVersionString() {
        const str = this.getVersionString();
        if (str) {
            return `${str} / `
        }
    }

    /**
     * @TODO 추후 Itembox 에 인터페이스 추가해야함
     * @returns
     */
    getOptionString() {
        const strs = [];
        switch (this.itemType) {
            case 'lp1':
                this.version && strs.push(this.getVersionString())
                this.lpep && strs.push(this.lpep.toUpperCase());
                break;
            case 'lp2':
                this.version && strs.push(this.getVersionString())
                this.translateIndex() && strs.push(this.translateIndex())
                this.translateAdapter() && strs.push(this.translateAdapter())
                break;
            case 'tc1':
                this.version && strs.push(this.getVersionString())
                this.translateIndex() && strs.push(this.translateIndex());
                this.tc1Enhancer && strs.push('Methyl enhancer')
                break;
            case 'tc2':
                this.version && strs.push(this.getVersionString())
                this.tc2Enhancer && strs.push('Hyb enhancer')
                break;
            case 'tc3':
                this.version && strs.push(this.getVersionString())
                this.probeID && strs.push(this.probeID)
                this.probeState && strs.push(this.probeState)
                this.version === 'v3' && strs.push(this.translateProbeConc())
                break;
            default:
                return '';
        }
        return strs.join(' / ');
    }

    getCatNO() {
        if (this.version === 'v1') {
            return null;
        }
        const item = this.getItemBox();
        if (item.isCatNoHasQuestion()) {
            return '';
        }
        else {
            return item.catNo;
        }
    }

    translatePlatform(platform: string = this.platform) {
        switch (platform) {
            case 'illumina':
                return 'Illumina';
            case 'ionTorrent':
                return 'Ion Torrent';
            case 'mgi':
                return 'MGI';
            default:
                return '';
        }
    }

    translateIndex(indexType: string = this.indexType) {
        switch (indexType) {
            case 'single':
                return 'Single';
            case 'ad153':
                return 'MGI Index';
            case 'dual':
                return 'Dual';
            case 'ionXpress':
                return 'Ion Xpress';
            case 'udi':
                return 'UDI';
            case 'umi':
                return 'UMI';
            case 'mgiDual':
                return 'MGI Dual Index';
            default: return indexType;
        }
    }

    translateAdapter(adapter: string = this.adapterType) {
        switch (adapter) {
            case 'methyl':
                return 'Methyl Adapter';
            case 'mgi1':
                return 'MGI Adapter V1';
            case 'mgi2':
                return 'MGI Adapter V2';
            case 'normal':
                return 'Short Adapter';
            case 'p1':
                return 'P1 Adapter';
            case 'short':
                return 'Short Adapter';
            case 'umi':
                return 'UMI Adapter';
            case 'ushape':
                return 'U-Shape Adapter';
            case 'mgiDual':
                return 'MGI Dual Adapter';
            default: return adapter;
        }
    }
}