import React from 'react';
import { TableCell, FormControl, InputLabel, Select, MenuItem, Grid, TableRow, TextField } from '@material-ui/core';
import ETSField from '../../../../common/component/ExtendedMUISTextfield';
import { observer } from 'mobx-react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateUtil from '@date-io/date-fns';
import ManufactureItem from '../../../../../data/model/manufacture/ManufactureItem';
import FormUtil from '../../../../../module/FormUtil';
import Helper from '../../../../../module/Helper';
import { ManufactureItemOptionRadioSelector, ManufactureItemOptionsCheckbox } from './CommonComponents';
import ManufactureWork from '../../../../../data/model/manufacture/ManufactureWork';
import ManufacturePanel from '../../../../../data/model/manufacture/ManufacturePanel';

export interface ManufactureItemFormRowPrpos {
    readonlyHeaders: (keyof ManufactureItem)[];
    inputHeaders: (keyof ManufactureItem)[];
    data: ManufactureItem;
    productType?: 'illumina' | 'ionTorrent' | 'etc' | '' | 'none';
    align?: "inherit" | "left" | "center" | "right" | "justify";
    buttons?: JSX.Element[];
    index?: number;
    work?: ManufactureWork;
    panelList?: ManufacturePanel[];
}

const setProbeID = (e: React.ChangeEvent<HTMLInputElement> | any, data: ManufactureItem, work: ManufactureWork, panelList: ManufacturePanel[]): void => {
    // const setProbeID = (e: React.ChangeEvent<HTMLInputElement> | any, data: ManufactureItem): void => {
    data.itemName = e.target.value;
    let panelInfo = new ManufacturePanel();
    if (e.target.value === 'TC #3' && work.presetPanelName !== '') {
        panelInfo = panelList.find(a => a.catalogPanelName === work.presetPanelName);
        data.probeID = panelInfo.probeID
    } else {
        data.probeID = ''
    }
    if (e.target.value === 'TC#3' && work.presetPanelName !== '') {
        panelInfo = panelList.find(a => a.catalogPanelName === work.presetPanelName);
        data.probeID = panelInfo.probeID
    } else {
        data.probeID = ''
    }
}

const getColumnByHeader = (
    header: keyof ManufactureItem,
    data: ManufactureItem,
    readOnly: boolean = false,
    productType: 'illumina' | 'ionTorrent' | 'etc' | '' | 'none' = '',
    align: "inherit" | "left" | "center" | "right" | "justify" = 'inherit',
    work: ManufactureWork,
    panelList: ManufacturePanel[],
): JSX.Element => {
    switch (header) {
        case ManufactureItem.HeaderKeyList.itemName:
            if (readOnly) {
                return (
                    <TableCell align={align}>
                        <ETSField
                            fullWidth
                            margin='normal'
                            rootdata={data}
                            value={data[header]}
                            name={header}
                            readOnly={readOnly}
                        />
                    </TableCell>
                )
            } else {
                return (
                    <TableCell align={align}>
                        <TextField
                            fullWidth
                            margin='normal'
                            label={FormUtil.getFieldLabel(header)}
                            onChange={(e) => setProbeID(e, data, work, panelList)}
                            // onChange={(e) => setProbeID(e, data)}
                            value={data[header]}
                            name={header}
                            helperText={
                                Helper.getErrorText(data.errors?.itemName)
                            }
                            error={
                                Helper.getErrorState(data.errors?.itemName)
                            }
                        />
                    </TableCell>
                )
            }
        case ManufactureItem.HeaderKeyList.numberPerQty:
            return (
                <TableCell align={align}>
                    <ETSField
                        style={{ width: 60 }}
                        margin='normal'
                        rootdata={data}
                        value={data.numberPerQty || ''}
                        name={header}
                        readOnly={readOnly}
                        type='number'
                        onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                    />
                </TableCell>
            )
        case ManufactureItem.HeaderKeyList.unit:
            if (readOnly) {
                return (
                    <TableCell align={align}>
                        <ETSField
                            style={{ width: 50 }}
                            rootdata={data}
                            value={data.unit}
                            name={header}
                            margin='normal'
                            readOnly
                        />
                    </TableCell>
                )
            }
            else {
                return (
                    <TableCell align={align}>
                        <FormControl
                            style={{ width: 60 }}
                            margin='normal'>
                            <InputLabel id={header}>
                                {FormUtil.getFieldLabel(header)}
                            </InputLabel>
                            <Select
                                disabled={readOnly}
                                error={
                                    Helper.getErrorState(
                                        data?.errors[header]
                                    )
                                }
                                labelId={header}
                                onChange={data.onChangeDefault}
                                value={data[header]}
                                name={header}>
                                {Object.keys(ManufactureItem.UnitList).map((v, i) => (
                                    <MenuItem key={`unitKey${i}`} value={v}>{(ManufactureItem.UnitList as any)[v]}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </TableCell>
                )
            }
        case ManufactureItem.HeaderKeyList.qty:
            return (
                <TableCell align={align}>
                    <ETSField
                        style={{ width: 60 }}
                        margin='normal'
                        rootdata={data}
                        value={data[header] || ''}
                        name={header}
                        readOnly={readOnly}
                        type='number'
                        onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                    />
                </TableCell>
            )
        case ManufactureItem.HeaderKeyList.pooling:
            return (
                <TableCell align={align}>
                    <ETSField
                        style={{ width: 60 }}
                        margin='normal'
                        rootdata={data}
                        value={data[header] || ''}
                        name={header}
                        readOnly={readOnly}
                        type='number'
                        onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                    />
                </TableCell>
            )
        case ManufactureItem.HeaderKeyList.hybRxn:
            return (
                <TableCell align={align}>
                    <ETSField
                        style={{ width: 60 }}
                        margin='normal'
                        rootdata={data}
                        value={data[header] || ''}
                        name={header}
                        readOnly={readOnly}
                        type='number'
                        onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                    />
                </TableCell>
            )
        case ManufactureItem.HeaderKeyList.probeID:
            if (data.itemName === 'TC#3' || productType === 'etc') {
                if (!work?.presetPanelName) {
                    return (
                        <TableCell align={align}>
                            <ETSField
                                fullWidth
                                style={{
                                    whiteSpace: 'pre-line',
                                }}
                                margin='normal'
                                rootdata={data}
                                value={data[header] || ''}
                                name={header}
                                readOnly={readOnly}
                            />
                        </TableCell>
                    )
                } else {
                    return (
                        <TableCell align={align}>
                            <ETSField
                                fullWidth
                                style={{
                                    whiteSpace: 'pre-line'
                                }}
                                margin='normal'
                                rootdata={data}
                                value={data[header] || ''}
                                name={header}
                                readOnly={true}
                            />
                        </TableCell>
                    )
                }
            }
            else {
                return (
                    <TableCell align={align}>
                        <ETSField
                            fullWidth
                            style={{
                                whiteSpace: 'pre-line'
                            }}
                            margin='normal'
                            rootdata={data}
                            value={data[header] || ''}
                            name={header}
                            readOnly={true}
                        />
                    </TableCell>
                )
            }
        case ManufactureItem.HeaderKeyList.remark:
            return (
                <TableCell align={align}>
                    <ETSField
                        fullWidth
                        style={{
                            whiteSpace: 'pre-line',
                        }}
                        multiline
                        margin='normal'
                        rootdata={data}
                        value={data[header] || ''}
                        name={header}
                        readOnly={readOnly}
                    />
                </TableCell>
            )
        case ManufactureItem.HeaderKeyList.panelName:
            return (
                <TableCell align={align}>
                    <ETSField
                        fullWidth
                        style={{
                            whiteSpace: 'pre-line',
                        }}
                        margin='normal'
                        rootdata={data}
                        value={data[header] || ''}
                        name={header}
                        readOnly={readOnly}
                    />
                </TableCell>
            )
        case ManufactureItem.HeaderKeyList.lotNO:
            return (
                <TableCell align={align}>
                    <ETSField
                        fullWidth
                        style={{
                            whiteSpace: 'pre-line',
                        }}
                        margin='normal'
                        rootdata={data}
                        value={data[header]}
                        name={header}
                        readOnly={readOnly}
                    />
                </TableCell>
            )
        case ManufactureItem.HeaderKeyList.catNO:
            return (
                <TableCell align={align}>
                    <ETSField
                        fullWidth
                        style={{
                            whiteSpace: 'pre-line',
                        }}
                        margin='normal'
                        rootdata={data}
                        // value={Helper.padZeroCatNORxn(typeof data[header] === 'string' ? data[header] as string : '')}
                        value={data.manipulatedCatNO ? data.manipulatedCatNO : ''}
                        name={header}
                        readOnly={readOnly}
                    />
                </TableCell>
            )
        case ManufactureItem.HeaderKeyList.expDate:
            return (
                <TableCell align={align}>
                    <FormControl style={{ minWidth: 120 }} margin='normal'>
                        <KeyboardDatePicker
                            disabled={readOnly}
                            openTo='year'
                            views={['year', 'month']}
                            variant='inline'
                            format='yyyy/MM'
                            label={FormUtil.getFieldLabel(header)}
                            value={
                                data.expDate
                            }
                            onChange={(date) => { data.onChangeDateDefault(header, date) }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date'
                            }}
                            helperText={
                                Helper.getErrorText(
                                    data.errors?.expDate
                                )
                            }
                            error={
                                Helper.getErrorState(
                                    data.errors?.expDate
                                )
                            }
                        />
                    </FormControl>
                </TableCell>
            )
        case ManufactureItem.HeaderKeyList.storageTemp:
            return (
                <TableCell align={align}>
                    <FormControl
                        style={{ width: 100 }}
                        margin='normal'>
                        <InputLabel id={header}>
                            {FormUtil.getFieldLabel(header)}
                        </InputLabel>
                        <Select
                            disabled={readOnly}
                            error={
                                Helper.getErrorState(
                                    data?.errors[header]
                                )
                            }
                            labelId={header}
                            onChange={data.onChangeDefault}
                            value={data[header]}
                            name={header}>
                            {Object.keys(ManufactureItem.StorageTempList).map((v, i) => (
                                <MenuItem key={`storage${i}`} value={v}>{(ManufactureItem.StorageTempList as any)[v]}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </TableCell>
            )
        case ManufactureItem.HeaderKeyList.shippingCondition:
            return (
                <TableCell align={align}>
                    <FormControl
                        style={{ width: 100 }}
                        margin='normal'>
                        <InputLabel id={header}>
                            {FormUtil.getFieldLabel(header)}
                        </InputLabel>
                        <Select
                            disabled={readOnly}
                            error={
                                Helper.getErrorState(
                                    data?.errors[header]
                                )
                            }
                            labelId={header}
                            onChange={data.onChangeDefault}
                            value={data[header]}
                            name={header}>
                            {Object.keys(ManufactureItem.ShippingCoditionList).map((v, i) => (
                                <MenuItem key={`shipping${i}`} value={v}>{(ManufactureItem.ShippingCoditionList as any)[v]}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </TableCell>
            )
        case ManufactureItem.HeaderKeyList.boxNO:
            return (
                <TableCell align={align}>
                    <ETSField
                        style={{ minWidth: 200 }}
                        margin='normal'
                        rootdata={data}
                        value={data[header]}
                        name={header}
                        readOnly={readOnly}
                    />
                </TableCell>
            )
        default:
            return (
                <TableCell>
                    NO DATA
                </TableCell>
            )
    }
}

const renderJSX = (jsx: JSX.Element) => {
    return jsx;
}

const renderOptionComponent = (data: ManufactureItem, readonly: boolean = false) => {
    data.itemOptions.readonly = readonly;
    switch (data.itemName.toLowerCase()) {
        case 'lp#1':
            data.itemOptions.itemType = 'lp1';
            return (
                <React.Fragment>
                    <Grid container>
                        <Grid item xl lg sm md>
                            <ManufactureItemOptionRadioSelector
                                optionKey="platform"
                                value={data.itemOptions.platform}
                                selections={[
                                    {
                                        label: "Illumina",
                                        value: "illumina"
                                    },
                                    {
                                        label: "MGI",
                                        value: "mgi"
                                    },
                                    {
                                        label: "Ion Torrent",
                                        value: "ionTorrent"
                                    },
                                ]}
                                title="Platform"
                                disabled={readonly}
                                error={data.itemOptions.errors.platform?.isError}
                                errorText={data.itemOptions.errors.platform?.errorText}
                                onValueChange={data.itemOptions.onChangeOptionValue}
                            />
                        </Grid>
                        <Grid item xl lg sm md>
                            <ManufactureItemOptionRadioSelector
                                optionKey="lpep"
                                value={data.itemOptions.lpep}
                                selections={[
                                    {
                                        label: "LP",
                                        value: "lp"
                                    },
                                    {
                                        label: "EP",
                                        value: "ep"
                                    },
                                ]}
                                title="LP/EP"
                                disabled={readonly || data.itemOptions.platform === null}
                                error={data.itemOptions.errors.lpep?.isError}
                                errorText={data.itemOptions.errors.lpep?.errorText}
                                onValueChange={data.itemOptions.onChangeOptionValue}
                            />
                        </Grid>
                    </Grid>
                </React.Fragment>
            )
        case 'lp#2':
            data.itemOptions.itemType = 'lp2';
            return (
                <React.Fragment>
                    <Grid container>
                        <Grid item xl lg sm md>
                            <ManufactureItemOptionRadioSelector
                                disabled={readonly}
                                optionKey="platform"
                                value={data.itemOptions.platform}
                                selections={[
                                    {
                                        label: "Illumina",
                                        value: "illumina"
                                    },
                                    {
                                        label: "MGI",
                                        value: "mgi"
                                    },
                                    {
                                        label: "Ion Torrent",
                                        value: "ionTorrent"
                                    },
                                ]}
                                title="Platform"
                                error={data.itemOptions.errors.platform?.isError}
                                errorText={data.itemOptions.errors.platform?.errorText}
                                onValueChange={data.itemOptions.onChangeOptionValue}
                            />
                        </Grid>
                        <Grid item xl lg sm md>
                            <ManufactureItemOptionRadioSelector
                                disabled={readonly || (data.itemOptions.platform ? false : true)}
                                optionKey="indexType"
                                value={data.itemOptions.indexType}
                                selections={
                                    data.itemOptions.platform ?
                                        data.itemOptions.getIndexTypeList().map(v => {
                                            return {
                                                label: data.itemOptions.translateIndex(v.value),
                                                value: v.value,
                                            }
                                        })
                                        :
                                        []
                                }
                                title="Index"
                                error={data.itemOptions.errors.indexType?.isError}
                                errorText={data.itemOptions.errors.indexType?.errorText}
                                onValueChange={data.itemOptions.onChangeOptionValue}
                            />
                        </Grid>
                        <Grid item xl lg sm md>
                            <ManufactureItemOptionRadioSelector
                                disabled={readonly || (data.itemOptions.platform ? false : true)}
                                optionKey="adapterType"
                                value={data.itemOptions.adapterType}
                                selections={
                                    data.itemOptions.platform ?
                                        data.itemOptions.getAdapterList().map(v => {
                                            return {
                                                label: data.itemOptions.translateAdapter(v.value),
                                                value: v.value,
                                            }
                                        })
                                        :
                                        []
                                }
                                title="Adapter"
                                error={data.itemOptions.errors.adapterType?.isError}
                                errorText={data.itemOptions.errors.adapterType?.errorText}
                                onValueChange={data.itemOptions.onChangeOptionValue}
                            />
                        </Grid>
                    </Grid>
                </React.Fragment>
            )
        case 'tc#1':
            data.itemOptions.itemType = 'tc1';
            return (
                <React.Fragment>
                    <Grid container>
                        <Grid item xl lg sm md>
                            <ManufactureItemOptionRadioSelector
                                disabled={readonly}
                                optionKey="platform"
                                value={data.itemOptions.platform}
                                selections={[
                                    {
                                        label: "Illumina",
                                        value: "illumina"
                                    },
                                    {
                                        label: "MGI",
                                        value: "mgi"
                                    },
                                    {
                                        label: "Ion Torrent",
                                        value: "ionTorrent"
                                    },
                                ]}
                                title="Platform"
                                error={data.itemOptions.errors.platform?.isError}
                                errorText={data.itemOptions.errors.platform?.errorText}
                                onValueChange={data.itemOptions.onChangeOptionValue}
                            />
                        </Grid>
                        <Grid item xl lg sm md>
                            <ManufactureItemOptionRadioSelector
                                disabled={readonly || (data.itemOptions.platform ? false : true)}
                                optionKey="indexType"
                                value={data.itemOptions.indexType}
                                selections={
                                    data.itemOptions.platform ?
                                        data.itemOptions.getIndexTypeList().map(v => {
                                            return {
                                                label: data.itemOptions.translateIndex(v.value),
                                                value: v.value,
                                            }
                                        })
                                        :
                                        []
                                }
                                title="Index"
                                error={data.itemOptions.errors.indexType?.isError}
                                errorText={data.itemOptions.errors.indexType?.errorText}
                                onValueChange={data.itemOptions.onChangeOptionValue}
                            />
                        </Grid>
                        <Grid item xl lg sm md>
                            <ManufactureItemOptionsCheckbox
                                disabled={readonly || (data.itemOptions.platform ? false : true)}
                                optionKey="tc1Enhancer"
                                value={data.itemOptions.tc1Enhancer}
                                title="Methyl enhancer"
                                error={data.itemOptions.errors.tc1Enhancer?.isError}
                                errorText={data.itemOptions.errors.tc1Enhancer?.errorText}
                                onValueChange={data.itemOptions.onChangeOptionValue}
                            />
                        </Grid>
                    </Grid>
                </React.Fragment>
            )
        case 'tc#2':
            data.itemOptions.itemType = 'tc2';
            return (
                <React.Fragment>
                    <Grid container>
                        <Grid item xl lg sm md>
                            <ManufactureItemOptionRadioSelector
                                disabled={readonly}
                                optionKey="platform"
                                value={data.itemOptions.platform}
                                selections={[
                                    {
                                        label: "Illumina",
                                        value: "illumina"
                                    },
                                    {
                                        label: "MGI",
                                        value: "mgi"
                                    },
                                    {
                                        label: "Ion Torrent",
                                        value: "ionTorrent"
                                    },
                                ]}
                                title="Platform"
                                error={data.itemOptions.errors.platform?.isError}
                                errorText={data.itemOptions.errors.platform?.errorText}
                                onValueChange={data.itemOptions.onChangeOptionValue}
                            />
                        </Grid>
                        <Grid item xl lg sm md>
                            <ManufactureItemOptionsCheckbox
                                disabled={readonly || (data.itemOptions.platform ? false : true)}
                                optionKey="tc2Enhancer"
                                value={data.itemOptions.tc2Enhancer}
                                title="Hyb enhancer"
                                error={data.itemOptions.errors.tc2Enhancer?.isError}
                                errorText={data.itemOptions.errors.tc2Enhancer?.errorText}
                                onValueChange={data.itemOptions.onChangeOptionValue}
                            />
                        </Grid>
                    </Grid>
                </React.Fragment>
            )
        case 'tc#3':
            data.itemOptions.itemType = 'tc3';
            return (
                <React.Fragment>
                    <Grid container>
                        <Grid item xl lg sm md>
                            <ManufactureItemOptionRadioSelector
                                disabled={readonly}
                                optionKey="platform"
                                value={data.itemOptions.platform}
                                selections={[
                                    {
                                        label: "Illumina",
                                        value: "illumina"
                                    },
                                    {
                                        label: "MGI",
                                        value: "mgi"
                                    },
                                    {
                                        label: "Ion Torrent",
                                        value: "ionTorrent"
                                    },
                                ]}
                                title="Platform"
                                error={data.itemOptions.errors.platform?.isError}
                                errorText={data.itemOptions.errors.platform?.errorText}
                                onValueChange={data.itemOptions.onChangeOptionValue}
                            />
                        </Grid>
                        <Grid item xl lg sm md>
                            <ManufactureItemOptionRadioSelector
                                disabled={readonly}
                                optionKey="probeState"
                                value={data.itemOptions.probeState}
                                selections={[
                                    {
                                        label: "Solution",
                                        value: "solution"
                                    },
                                    {
                                        label: "Powder",
                                        value: "powder"
                                    },
                                ]}
                                title="Probe state"
                                error={data.itemOptions.errors.probeState?.isError}
                                errorText={data.itemOptions.errors.probeState?.errorText}
                                onValueChange={data.itemOptions.onChangeOptionValue}
                            />
                        </Grid>
                        {(data.itemOptions.version === 'v3') &&
                            <Grid item xl lg sm md>
                                <ManufactureItemOptionRadioSelector
                                    disabled={readonly}
                                    optionKey="probeConc"
                                    value={data.itemOptions.probeConc}
                                    selections={[
                                        {
                                            label: "High (기본)",
                                            value: "high"
                                        },
                                        {
                                            label: "Low (Single-plexing, size 1백만kb 이상)",
                                            value: "low"
                                        },
                                        {
                                            label: "Methyl",
                                            value: "methyl"
                                        },
                                        {
                                            label: "Methyl High",
                                            value: "methylHigh"
                                        },
                                        {
                                            label: "Methyl Low",
                                            value: "methylLow"
                                        },
                                        {
                                            label: "etc.",
                                            value: "etc"
                                        },
                                    ]}
                                    title="Probe Conc."
                                    error={data.itemOptions.errors.probeConc?.isError}
                                    errorText={data.itemOptions.errors.probeConc?.errorText}
                                    onValueChange={data.itemOptions.onChangeOptionValue}
                                />
                            </Grid>
                        }
                    </Grid>
                </React.Fragment>
            )
        case 'tc #3':
            data.itemOptions.itemType = 'tc3';
            return (
                <React.Fragment>
                    <Grid container>
                        <Grid item xl lg sm md>
                            <ManufactureItemOptionRadioSelector
                                disabled={readonly}
                                optionKey="platform"
                                value={data.itemOptions.platform}
                                selections={[
                                    {
                                        label: "Illumina",
                                        value: "illumina"
                                    },
                                    {
                                        label: "MGI",
                                        value: "mgi"
                                    },
                                    {
                                        label: "Ion Torrent",
                                        value: "ionTorrent"
                                    },
                                ]}
                                title="Platform"
                                error={data.itemOptions.errors.platform?.isError}
                                errorText={data.itemOptions.errors.platform?.errorText}
                                onValueChange={data.itemOptions.onChangeOptionValue}
                            />
                        </Grid>
                        <Grid item xl lg sm md>
                            <ManufactureItemOptionRadioSelector
                                disabled={readonly}
                                optionKey="probeState"
                                value={data.itemOptions.probeState}
                                selections={[
                                    {
                                        label: "Solution",
                                        value: "solution"
                                    },
                                    {
                                        label: "Powder",
                                        value: "powder"
                                    },
                                ]}
                                title="Probe state"
                                error={data.itemOptions.errors.probeState?.isError}
                                errorText={data.itemOptions.errors.probeState?.errorText}
                                onValueChange={data.itemOptions.onChangeOptionValue}
                            />
                        </Grid>
                    </Grid>
                </React.Fragment>
            )
        case 'cDNA Synthesis Kit'.toLowerCase():
            data.itemOptions.itemType = 'cDNA';
            return (
                <React.Fragment>
                    <Grid container>
                        <Grid item xl lg sm md>
                            cDNA Synthesis Kit ✔
                        </Grid>
                    </Grid>
                </React.Fragment>
            )
        case 'NM bead'.toLowerCase():
            data.itemOptions.itemType = 'accClmBead';
            return (
                <React.Fragment>
                    <Grid container>
                        <Grid item xl lg sm md>
                            CeleNM Bead ✔
                        </Grid>
                    </Grid>
                </React.Fragment>
            )
        case 'Clean-up bead'.toLowerCase():
            data.itemOptions.itemType = 'accClmCelanUpBead';
            return (
                <React.Fragment>
                    <Grid container>
                        <Grid item xl lg sm md>
                            CeleMag Celan-Up Bead ✔
                        </Grid>
                    </Grid>
                </React.Fragment>
            )
        case 'Streptavidin bead'.toLowerCase():
            data.itemOptions.itemType = 'accClmStreptavidinBead';
            return (
                <React.Fragment>
                    <Grid container>
                        <Grid item xl lg sm md>
                            CeleMag Streptavidin Bead ✔
                        </Grid>
                    </Grid>
                </React.Fragment>
            )
        case 'Polymerase'.toLowerCase():
            data.itemOptions.itemType = 'accClmPolymerase';
            return (
                <React.Fragment>
                    <Grid container>
                        <Grid item xl lg sm md>
                            CLM Polymerase ✔
                        </Grid>
                    </Grid>
                </React.Fragment>
            )
        case 'NM bead binding buffer'.toLowerCase():
            data.itemOptions.itemType = 'accClmBeadBindingBuffer';
            return (
                <React.Fragment>
                    <Grid container>
                        <Grid item xl lg sm md>
                            NM bead binding buffer ✔
                        </Grid>
                    </Grid>
                </React.Fragment>
            )
        default:
            return (
                <React.Fragment>
                    <Grid container>
                        <Grid item xl lg sm md>
                            NO OPTIONS
                        </Grid>
                    </Grid>
                </React.Fragment>
            )
    }
}

const ManufactureItemFormRow: React.FC<ManufactureItemFormRowPrpos> = observer((props) => {
    const readonlyHeaders = props.readonlyHeaders || [];
    const inputHeaders = props.inputHeaders || [];
    const data = props.data;
    const productType = props.productType;
    const align = props.align || 'inherit';
    const buttons = props.buttons || [] as JSX.Element[];
    const index = props.index || 0;
    const work = props.work;
    const panelList = props.panelList;

    return (
        <MuiPickersUtilsProvider utils={DateUtil} key={`prov${index}`}>
            <React.Fragment key={`contfrag${index}`}>
                <TableRow style={{ borderTop: "0px", borderBottom: "0px" }}>
                    <TableCell rowSpan={2}>
                        {index + 1}
                    </TableCell>
                    {readonlyHeaders.map((v, i) =>
                        <React.Fragment key={`itemcol1${index}${i}`}>
                            {getColumnByHeader(v, data, true, productType, align, work, panelList)}
                            {/* {getColumnByHeader(v, data, true, productType, align)} */}
                        </React.Fragment>
                    )}
                    {inputHeaders.map((v, i) =>
                        <React.Fragment key={`itemcol2${index}${i}`}>
                            {getColumnByHeader(v, data, false, productType, align, work, panelList)}
                            {/* {getColumnByHeader(v, data, false, productType, align)} */}
                        </React.Fragment>
                    )}
                    {buttons.map((v, i) => (
                        <TableCell key={`buttons${index}${i}`} align='center' rowSpan={2}>
                            {renderJSX(v)}
                        </TableCell>
                    ))}
                </TableRow>
                <TableRow key={`itemRow${index}`} style={{ borderBottom: "2px solid", borderTop: "0px" }}>
                    <TableCell colSpan={readonlyHeaders.length + inputHeaders.length + buttons.length + 1}>
                        <Grid container direction='column' spacing={1}>
                            <Grid item xl lg sm md>
                                Version: {data.itemOptions.version}
                            </Grid>
                            <Grid item xl lg sm md>
                                {renderOptionComponent(data, (readonlyHeaders.length > 0 || data.itemOptions.readonly))}
                            </Grid>
                        </Grid>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        </MuiPickersUtilsProvider>
    )
})

export default ManufactureItemFormRow;